@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

.body {
  font-family: 'Roboto', sans-serif;
}

:root {
  --gray: #2C2D2F;
}
.category-img-container{

  height: 2rem;
  width: 2rem;
}

.lateral-bar {
  grid-area: 1 / 1 / 2 / 2;
  background-color: var(--gray);
  min-width: 248px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  max-width: 248px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.lateral-bar-content{
  position: fixed;

}
.store-icons {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
}

[class*='divide-row'] {
  min-height: 50vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

}


.divide-row-text-businessApp {
  align-items: center;
}

.category-card {
  min-height: 5rem;
  width: 4rem;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  border: 1px solid #2C2D2F;
  border-radius: 6px;
  align-items: center;
  word-break: break-all;
}

.category-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
}

.image-uploaded-card {
  min-width: 5rem;
  min-height: 5rem;
  max-width: 5rem;
  max-height: 5rem;
  border-radius: 10px;
  background-color: red;
}

.fill {
  max-width: 5rem;
  max-height: 5rem;
  object-fit: fill;

}

.image-drag-and-drop {
  border-radius: 10px;
  background: linear-gradient(0deg, rgba(222, 99, 44, 1) 0%, rgba(253, 187, 45, 1) 100%);
  min-height: 20rem;
  min-width: 26rem;
  display: flex;
  flex-direction: column;
  color: white;
  justify-content: space-evenly;
  align-items: center;
}

.card-category-border {
  word-break: break-all;
}

.text-field-container {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}

.text-field-label {
  min-width: 30%;
  align-self: flex-end;
}

.menu-card {
  height: 15rem;
  width: 25rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px -6px black;
}

.search-bar-content {
  min-width: 100%;
  min-height: 3rem;
  gap: 1%;
  margin-top: 3%;
  margin-bottom: 3%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.search-bar-button {
  align-self: center;
  max-height: 2.5rem;
}

.search-bar-container {
  background-color: white;
  width: 40%;
  align-items: center;
  min-height: 2rem;
  border-radius: 9px;
  box-shadow: 0 4px 6px -6px black;
  display: flex;
  flex-direction: row;
}

.search-bar {
  outline: none;
  border-color: transparent;
  min-height: 60%;
  min-width: 90%;
  font-size: 20px;
}

.search-bar:focus {
  border-color: transparent;
}

.card-content {
  display: flex;
  flex-direction: row;
  padding-top: 8px;
  padding-left: 8px;
  gap: 2%;
}

.card-content-left {
  flex-basis: 29%;
}

.card-content-right {
  flex-basis: 59%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-buttons {
  display: flex;
  flex-direction: row;
  padding-top: 15%;
  justify-content: space-between;
}

.menu-card-img {
  min-width: 7rem;
  min-height: 7rem;
  border-radius: 10px;
  box-shadow: 0 5px 9px -6px black;

  display: flex;
  justify-content: center;
  align-items: center;
}

.review-rating {
  padding-top: 1rem;
  padding-left: 1rem;
}

[class*='review-card-col'] {
  display: flex;
  flex-direction: column;
  flex-basis: 50%;

}


.review-card-row {
  display: flex;
  flex-direction: row;
}

.review-card {
  width: 22rem;
  min-height: 15rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px -6px black;

}
.login-content{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
  margin-left: 8rem;
  margin-right: 8rem;
}
.login-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  width: 100vw;
  height: 100vh;
}
.login-logo{
  width: 150px;

}
.img-login-container{
  align-self: center;
  padding-top: 1rem;
}
.login-box {
  display: flex;
  flex-direction: column;
  align-self: center;
  background-color: white;
  box-shadow: 0 4px 11px -6px black;
  width: 27vw;
  min-height: 50vh;
  border-radius: 20px;
}
.data-policy{
  display: flex;
  flex-direction: column;
}
.header-data-policy{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  width: 100vw;
  background-color: black;
  color: white;
}
.image-uploaded-outside{
  width: 6rem;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.delete-item{
  position: absolute;
  align-self: start;
  background-color: white;
  border-radius: 20px;
  margin-left: 2.2rem;
  z-index: 1;
}
.container-banners{
  display: flex;
  justify-content: center;
  margin-top: 2%  ;
  flex-wrap: wrap;
  gap: 1rem;
}
.container-drag{
  display: flex;
  justify-content: center;
}
.areas-list{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 2rem;
}
.municipality-list{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height:100%;
}
.img-banner{
  height: 6.25rem;
  width: 12.5rem;
  border-radius: 15px;
}
.error-login-container{
  color:white;
  text-align: center;
  background-color: red;
  width: 100%;
  min-height: 2rem;
}
.menu-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  gap: 4px;
  justify-content: center;
}

.test-02 {
  min-width: 40%;
  display: flex;
  flex-direction: column;
  gap:1rem;

}

.page-container-business-basic-data {
  width: 90%;
}

[class*='page-container'] {
  display: flex;
  flex-direction: row;
  flex-basis: 50%;
  padding-left: 2%;
  padding-top: 5%;
  padding-right: 2%;
  justify-content: center;
  align-content: center;
  /*background-color: red;*/
  min-height: 80vh;
  width: 96%;
}

.page-container-business-app {
  padding-right: 10%;
  padding-left: 10%;
  width: 80%;

}

.up-section-lateral-bar {
  display: flex;
  justify-content: center;
}

.down-section-lateral-bar {
  font-weight: bold;
  color: white;
  display: flex;
  flex-direction: column;

}

.menu-item {
  padding-left: 20%;
}

.img-size {
  width: 80%;
}

.logo-lateral-bar {
  width: 50%;
}

.store-image {
  height: 100%;
}

.img-app-container {
  flex-basis: 20%;
}

.img-app-logo {
  width: 20%;
  padding-left: 10%;
}

.main-body {
  display: grid;
  width: 100vw;
  height: 100vh;
  grid-template-columns: 248px auto;
  grid-template-rows: auto;
}

.top-bar {
  height: 110px;
  background-color: white;
  box-shadow: 0 4px 6px -6px black;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.top-menu-items {
  flex-basis: 60%;
  padding-right: 20%;
  width: 30%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.main-content {
  grid-area: 1 / 2 / 2 / 3;
}

@media only screen and (max-width: 768px) {
  html, body {
    max-width: 100%;

  }

  .text-title-mobile {
    text-align: center;
  }

  .page-container-business-app {
    flex-direction: column;
    justify-content: center;
    padding-left: 2%;
    padding-right: 2%;
    width: 96%;
    height: 80%;
  }

  .top-bar {
    display: none;
  }

  .store-icons {
    align-items: center;
    padding-top: 6%;
    max-width: 100%;

  }

  .divide-row-text-businessApp {
    max-width: 96%;

  }

  .img-size {
    max-width: 100vw;
  }

  .text-width {
    width: 40%;
  }
}
